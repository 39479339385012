body {
  overflow: hidden;
}
.header {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  border-radius: 10px;
  background: #2093e7;
  width: 167px;
  height: 64px;
  outline: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  &:hover {
    background-color: #0377ca;
  }
}
.img-section {
  height: 100vh;
  width: 100vw;
}
.menu-main {
  width: 100%;
  height: 100%;
}

.menu2 {
  text-align: center;
}
.row-header {
  height: 33.35%;
}
.row {
  height: 100%;
}
.img-block {
  float: left;
  width: 25%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.img-styled {
  max-width: 100%;
  width: 100%;
  top: 70%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
}
.row {
  height: 100%;
}
.menu-btn {
  width: 60px;
  height: 50px;
  cursor: pointer;
}
.cur-disable {
  cursor: not-allowed;
}
.btn-active {
  background: brown;
}
.flexWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.text-xl {
  font-size: 100px;
  color: #e74c3c;
}
.text-md {
  font-size: 24px;
  margin-top: 10px;
  color: #333;
}
.menu2Btn-wrapper {
  display: flex;
  justify-content: end;
}
.timerImg {
  height: 100%;
}
@media (min-width: 600px) {
  .menu-main {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    visibility: hidden;
  }
  .image-12:hover .img-styled {
    visibility: hidden;
  }
  .image-12:hover .menu-main {
    visibility: visible;
  }
}

@media (max-width: 600px) {
  .row-header {
    height: 20%;
  }
  .menu-main {
    margin-bottom: 40px;
  }
  .img-section {
    height: calc(100vh - 115px);
  }
  .img-block {
    width: 33.33%;
  }
  .menu1,
  .menu2 {
    position: relative;
  }
  .menu1 {
    text-align: center;
  }
  .menu2Btn-wrapper {
    justify-content: center;
  }
  .menu2 {
    top: 10px;
    text-align: center;
  }
  .img-styled {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
